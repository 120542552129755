<template>
  <div>
    <p class="p-b-5">
      <Row :gutter="8">
        <i-col span="24">
          <div
            v-for="(item, index) in listContentCategory"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked(item.serialNumber)"
            :class="
              checkContentCategorys.includes(item.serialNumber)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.moduleName }}
          </div>
          <Button
            type="success"
            size="small"
            class="m-l-5"
            @click="showDownLoadProgrammeModal = true"
            >下载账单清单</Button
          >
          <Button
            type="success"
            size="small"
            class="m-l-5"
            @click="changeSettlement"
            >更改结算方</Button
          >
        </i-col>
      </Row>

      <!-- <span class="m-l-50 title">
              PS：运维对账操作仅可对<span class="text-orange">已质检</span>和<span class="text-orange">未对账</span>的项进行操作！
            </span> -->
    </p>
    <Table
      :loading="loading"
      stripe
      :data="list"
      :columns="tableColumns"
      @on-selection-change="selectionChange"
      :height="clientHeight"
    ></Table>

    <Row>
      <i-col span="12">
        <span style="height: 28px; line-height: 28px"
          >已选中{{ feeitemIds.length }}条</span
        >
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>

    <Update ref="updateModal"></Update>

    <Modal v-model="showDownLoadProgrammeModal">
      <div class="workplatform-title" slot="header">请选择下载类型</div>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
              <span>全部下载</span>
            </Radio>
            <Radio label="2">
              <span>勾选下载</span>
            </Radio>
          </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal = false"
          >取消</Button>
        <Button type="primary" @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { formatMainanceReconcilliationStatus } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'
import { createsettlementdetail } from '@/api/msp/msppreport'

import {
  getsettlementpagecontent,
  getsettlementdetailpage,
  getsettlements,
  batchmodifysettlement
} from '@/api/msp/settlement'
import { confirmfeeitems } from '@/api/statement/bill'

// import Update from './Update'

export default {
  components: {
    // Update
  },
  props: {
    params: Object
  },
  data () {
    return {
      clientHeight: 0,
      total: 0,
      loading: false,

      query: {
        pageNumber: 1,
        pageSize: 15,
        settlementCompanyId: 0,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      list: [],
      tableColumns: [],

      feeitemIds: [], // 页面选中的所有项
      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合
      currentPageSelectionIdArray: [],
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1',

      listContentCategory: [],
      checkContentCategorys: [1, 2, 3, 4]
    }
  },
  created () {
    this.$store.commit(
      'set_reconciliationDemo_notAllowReconciliationQuantity',
      0
    )
    this.feeitemIds = []
    this.clientHeight = document.documentElement.clientHeight - 180
    this.initsettlementpagecontent()
  },
  computed: {
    maintenanceReconciliationQuery () {
      return this.$store.state.reconciliationDemo
        .maintenanceReconciliationQuery
    },
    notAllowReconciliationQuantity () {
      return (
        this.$store.state.reconciliationDemo.notAllowReconciliationQuantity || 0
      )
    },
    beginUpdate () {
      return this.$store.state.reconciliationDemo.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.$store.commit(
        'set_reconciliationDemo_notAllowReconciliationQuantity',
        0
      )
      this.feeitemIds = []

      if (this.maintenanceReconciliationQuery.supplierCompanyId) {
        this.query.supplierCompanyId =
          this.maintenanceReconciliationQuery.supplierCompanyId
        this.query.settlementCompanyId =
          this.maintenanceReconciliationQuery.settlementCompanyId
        this.query.startDate = this.maintenanceReconciliationQuery.startDate
        this.query.endDate = this.maintenanceReconciliationQuery.endDate
        this.query.timeCategory =
          this.maintenanceReconciliationQuery.timeCategory
        this.query.keyword = this.maintenanceReconciliationQuery.keyword
        this.query.minCost = this.maintenanceReconciliationQuery.minCost
        this.query.maxCost = this.maintenanceReconciliationQuery.maxCost
        this.query.taskMainTypeFlag =
          this.maintenanceReconciliationQuery.taskMainTypeFlag
        this.query.status =
          this.maintenanceReconciliationQuery.status === -1
            ? null
            : this.maintenanceReconciliationQuery.status

        this.query.chargeStatus =
          this.maintenanceReconciliationQuery.chargeStatus === -1
            ? null
            : this.maintenanceReconciliationQuery.chargeStatus

        this.query.taskitemTypes =
          this.maintenanceReconciliationQuery.taskitemTypes
        this.query.resourcetypeIds =
          this.maintenanceReconciliationQuery.resourcetypeIds
        this.query.adTypes = this.maintenanceReconciliationQuery.adTypes
      }
      this.reloadPage()
    }
  },
  methods: {
    initsettlementpagecontent () {
      const that = this
      getsettlementpagecontent({}).then((res) => {
        that.listContentCategory = res
        that.initColumns()
      })
    },

    // 变更多选框选择
    changeChecked (value) {
      const that = this
      if (that.checkContentCategorys.includes(value)) {
        const index1 = that.checkContentCategorys.indexOf(value)
        if (index1 > -1) {
          that.checkContentCategorys.splice(index1, 1)
        }
      } else {
        that.checkContentCategorys.push(value)
      }
      that.initColumns()
    },
    initColumns () {
      const that = this
      const tableColumnArray = {
        selection: {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left'
        },
        settlementCompanyName: {
          title: '结算方',
          key: 'settlementCompanyName',
          minWidth: 100,
          align: 'center'
        },
        supplierCompanyName: {
          title: '供应商',
          key: 'supplierCompanyName',
          minWidth: 100,
          align: 'center'
        },
        resourceCode: {
          title: '资源编号',
          key: 'resourceCode',
          minWidth: 100,
          align: 'center'
        },
        skuName: {
          title: '单品名称',
          key: 'skuName',
          minWidth: 100,
          align: 'center'
        },
        assetName: {
          title: '线路',
          key: 'assetName',
          minWidth: 100,
          align: 'center'
        },
        stationName: {
          title: '站点',
          key: 'stationName',
          minWidth: 100,
          align: 'center'
        },
        positionName: {
          title: '位置',
          key: 'positionName',
          minWidth: 80,
          align: 'center'
        },
        resourceTypeName: {
          title: '资源类型',
          key: 'resourceTypeName',
          minWidth: 60,
          align: 'center'
        },

        taskTypeName: {
          title: '作业类型',
          key: 'taskTypeName',
          minWidth: 100,
          align: 'center'
        },
        statusName: {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          align: 'center',
          render: (h, data) => {
            return formatMainanceReconcilliationStatus(
              h,
              data.row.status,
              data.row.statusName
            )
          }
        },
        dispatchTime: {
          title: '派单日期',
          key: 'dispatchTime',
          minWidth: 100,
          align: 'center'
        },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          minWidth: 80,
          align: 'center'
        },
        finishTime: {
          title: '完成时间',
          key: 'finishTime',
          minWidth: 60,
          align: 'center'
        },
        qualityTestTime: {
          title: '质检时间',
          key: 'qualityTestTime',
          minWidth: 60,
          align: 'center'
        },
        chargeTime: {
          title: '对账时间',
          key: 'chargeTime',
          minWidth: 60,
          align: 'center'
        },

        materialUnitCost: {
          title: '物料类单价',
          key: 'materialUnitCost',
          minWidth: 100,
          align: 'center'
          // render: (h, params) => {
          //   return h('div', [
          //     h(
          //       toMoney(params.row.materialUnitCost)
          //     )
          //   ])
          // }
        },
        materialChargeQuantity: {
          title: '物料数量',
          key: 'materialChargeQuantity',
          minWidth: 80,
          align: 'center'
        },
        unitCost: {
          title: '人工类单价',
          key: 'unitCost',
          minWidth: 60,
          align: 'center'
        },
        chargeQuantity: {
          title: '人工数量',
          key: 'chargeQuantity',
          minWidth: 60,
          align: 'center'
        },
        amount: {
          title: '工单金额',
          key: 'amount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },

        createCompanyName: {
          title: '代理商',
          key: 'createCompanyName',
          minWidth: 100,
          align: 'center'
        },
        sellerName: {
          title: '销售主体',
          key: 'sellerName',
          minWidth: 100,
          align: 'center'
        },
        contractCode: {
          title: '合同编号',
          key: 'contractCode',
          minWidth: 80,
          align: 'center'
        },
        contractName: {
          title: '合同名称',
          key: 'contractName',
          minWidth: 60,
          align: 'center'
        },
        adTypeName: {
          title: '用途',
          key: 'adTypeName',
          minWidth: 60,
          align: 'center'
        },
        advertiserName: {
          title: '客户名称',
          key: 'advertiserName',
          minWidth: 60,
          align: 'center'
        },
        brandName: {
          title: '品牌',
          key: 'brandName',
          minWidth: 60,
          align: 'center'
        },

        dispatcherCompanyName: {
          title: '派单主体',
          key: 'dispatcherCompanyName',
          minWidth: 60,
          align: 'center'
        },
        dispatchUserName: {
          title: '派单人',
          key: 'dispatchUserName',
          minWidth: 60,
          align: 'center'
        },

        operate: {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 80,
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.updateModal.showModal(params.row)
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      }
      that.tableColumns = [tableColumnArray.selection]
      that.checkContentCategorys.forEach((item) => {
        const category = that.listContentCategory.filter(
          (x) => parseInt(x.serialNumber) === parseInt(item)
        )[0]
        if (category && category.rowNameList.length) {
          category.rowNameList.forEach((row) => {
            that.tableColumns.push(tableColumnArray[row.name])
          })
        }
      })

      if (!that.checkContentCategorys.length) {
        this.tableColumns = [
          { type: 'selection', width: 60, align: 'center', fixed: 'left' },
          {
            title: '资源编号',
            key: 'resourceCode',
            minWidth: 100,
            align: 'center'
          },
          {
            title: '工单金额',
            key: 'amount',
            minWidth: 60,
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'money'
                  },
                  toMoney(params.row.amount)
                )
              ])
            }
          }
        ]
      }

      this.tableColumns.push(tableColumnArray.operate)
    },
    reloadPage () {
      this.query.pageNumber = 1
      if (this.query.supplierCompanyId) {
        this.loadPage()
      }
    },
    loadPage () {
      this.loading = true
      const that = this
      getsettlementdetailpage(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = that.feeitemIds.includes(item.id)
            item.formatUnitCost = toMoney(item.unitCost)
            // item._disabled = (item.status === 2)
            return item
          })
          that.currentPageAllItemIds = res.list.map((item) => {
            return item.id
          })
        }

        that.list = res.list
        that.total = res.totalRow
        that.loading = false
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      var quantity = that.notAllowReconciliationQuantity
      const selectionIds = selection.map((item) => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        const currentItem = that.list.filter((x) => x.id === item)[0]
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity + 1
            }
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity - 1
            }
          }
        }
      })

      this.$store.commit(
        'set_reconciliationDemo_notAllowReconciliationQuantity',
        quantity
      )
    },
    confirmReconcilliation () {
      const that = this
      if (!that.feeitemIds.length) {
        that.$Notice.info({ desc: '请选择要对账的条目！' })
      } else {
        that.$Modal.confirm({
          title: '操作确认',
          content:
            '<p>是否要将所选条目标记为已对账，确认后<span style="color:red;font-weight:600;">无法再次变更</span>，请谨慎操作！</p>',
          loading: true,
          onOk: () => {
            confirmfeeitems({
              feeitemListJsonStr: JSON.stringify(that.feeitemIds),
              feeitemType: 2
            })
              .then((res) => {
                if (res && !res.errcode) {
                  this.$Notice.success({ desc: '成功提交对账！' })
                  that.feeitemIds = []
                  that.$store.commit(
                    'set_reconciliationDemo_update',
                    new Date()
                  )
                }
                that.$Modal.remove()
              })
              .catch(() => {
                that.$Modal.remove()
              })
          }
        })
      }
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {
        taskMainTypeFlag: that.maintenanceReconciliationQuery.taskMainTypeFlag,
        excelModules: JSON.stringify(that.checkContentCategorys)
      }
      if (parseInt(that.downLoadProgramme) === 1) {
        params.publisherId = that.query.publisherId
        params.supplierCompanyId =
          that.maintenanceReconciliationQuery.supplierCompanyId === '-1'
            ? null
            : that.maintenanceReconciliationQuery.supplierCompanyId
        params.settlementCompanyId =
          that.maintenanceReconciliationQuery.settlementCompanyId === '-1'
            ? null
            : that.maintenanceReconciliationQuery.settlementCompanyId
        params.startDate = that.maintenanceReconciliationQuery.startDate
        params.endDate = that.maintenanceReconciliationQuery.endDate
        params.timeCategory = this.maintenanceReconciliationQuery.timeCategory
        params.keyword = that.maintenanceReconciliationQuery.keyword
        params.minCost = that.maintenanceReconciliationQuery.minCost
        params.maxCost = that.maintenanceReconciliationQuery.maxCost
        params.status =
          that.maintenanceReconciliationQuery.status === -1
            ? null
            : that.maintenanceReconciliationQuery.status

        params.taskitemTypes =
          that.maintenanceReconciliationQuery.taskitemTypes
        params.resourcetypeIds =
          that.maintenanceReconciliationQuery.resourcetypeIds

        params.adTypes = that.maintenanceReconciliationQuery.adTypes
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.taskitemChargeIds = JSON.stringify(that.feeitemIds)
        }
      }

      that.downLoadExecl(params)
    },
    // 下载列表
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr =
            ParseDateForMonth(params.startDate) +
            '至' +
            ParseDateForMonth(params.endDate)
        }
      }
      const filename = '运维对账明细(' + dateStr + ').xlsx'
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h('Icon', {
              class: 'spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 24
              }
            }),
            h('div', '数据生成中...')
          ])
        }
      })

      createsettlementdetail(params).then(res => {
        params.uniqueFlag = res.uniqueFlag
        if (res.status === 2) {
          this.pollingCreate(params, filename)
        } else {
          setTimeout(() => {
            this.pollingCreate(params, filename)
          },
          1000)
        }
      })
    },
    pollingCreate (params, filename) {
      createsettlementdetail(params).then(res => {
        if (res.status === 2) {
          this.$Spin.hide()
          this.pollingDownload(params.uniqueFlag, filename)
        } else {
          setTimeout(() => {
            this.pollingCreate(params, filename)
          },
          1000)
        }
      })
    },
    pollingDownload (key, filename) {
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-msp/v2/msppreport/downloadsettlementdetail',
        { uniqueFlag: key },
        filename
      )
    },

    changeSettlement () {
      const that = this
      if (!that.feeitemIds.length) {
        that.$Notice.info({ desc: '请选择要更改结算方的条目！' })
      } else {
        getsettlements({}).then((res) => {
          var settlementId = 0
          that.$Modal.confirm({
            title: '更改结算方',
            render: (h) => {
              return [h('Select', {
                props: {
                  value: settlementId,
                  placeholder: ''
                },
                style: {
                  'margin-bottom': '10px'
                },
                on: {
                  'on-change': (event) => {
                    settlementId = event
                  }
                }
              },
              res.map(item => {
                return h('Option', {
                  props: {
                    value: item.companyId,
                    label: item.companyName
                  }
                })
              })
              )]
            },
            onOk: () => {
              if (settlementId) {
                const data = {
                  settlementId: settlementId,
                  taskitemChargeIds: JSON.stringify(that.feeitemIds)
                }
                batchmodifysettlement(data).then((res) => {
                  if (res && !res.errcode) {
                    that.$Notice.success({ desc: '更改结算方成功!' })
                    this.$store.commit('set_reconciliationDemo_update', new Date())
                  }

                // that.loadPage()
                })
              } else {
                that.$Notice.info({ desc: '请先选择结算方!' })
              }
            }
          })
          this.settlementcompanyArray = res
        })
      }
    }
  }
}
</script>
